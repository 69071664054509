<template>
  <base-section id="theme-features">
    <base-section-heading :title="translations.homeFeaturesTitle" />

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <base-btn
            :tile="false"
            color="secondary"
            @click="goToOffer"
          >
            {{ translations.homeFeaturesLinkName }}
          </base-btn>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import { STORE_NAMESPACE_HOME } from '@/views/home/Index.vue'
  import { PL, EN } from '@/constants/lang.js'

  export default {
    name: 'SectionHomeFeatures',
    computed: {
      ...mapState(STORE_NAMESPACE_HOME, [
        'translations',
      ]),
      features () {
        const homeFeatures = this.translations.homeFeatures
        return homeFeatures ? Object.keys(homeFeatures).map(key => homeFeatures[key]) : []
      },
    },
    methods: {
      goToOffer () {
        const name = this.$route.meta.lang === PL ? 'Offer-pl' : 'Offer-en'
        this.$router.push({ name })
      },
    },
  }
</script>
